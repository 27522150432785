/*
  the main reason to icon can not be align is that .folder has a real width it take the place
   of .tree-node-inner causing tree-node-icon not aligned , use absolute can make sure .tree-node-icon
   is always at the far left of .tree-node-inner
*/
.side-panel {
  user-select: none;
  height:100%;
}
.side-panel .nav {
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 125px;
  height: 36px;
}
.side-panel .nav-link {
  color: #888;
}
.side-panel .nav-link.active {
  color: #eb8205;
}
.side-panel-content {
  padding-top: 8px;
  padding-bottom: 8px;
  height: calc(100% - 36px);
  overflow: auto;
  font-size: 0.875rem;
}

.image-view {
  width: 200px;
  height: 150px;
  position: absolute;
  background-color: #fff;
  z-index: 1004;
  box-shadow: 0 0 10px #aaa;
  border-radius: 3px;
  line-height: 150px;
  overflow: hidden;
  font-size: 0;
  text-align: center;
}

.image-view img {
  max-width: 100%;
  max-height: 100%;
}

.image-view i {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 150px;
  font-size: 30px;
  color: #eb8205;
  -moz-animation: rotate 1.5s ease infinite;
  -webkit-animation: rotate 1.5s ease infinite;
  animation: rotate 1.5s ease infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.outline-h2 {
  margin-left: 20px;
  line-height: 2.5;
  color:#364149;
  white-space: nowrap;
  cursor:pointer;
}
.outline-h2:hover {
  color: #eb8205;
}
.outline-h3 {
  margin-left: 40px;
  line-height: 2.5;
  color:#364149;
  white-space: nowrap;
  cursor:pointer;
}
.outline-h3:hover {
  color: #eb8205;
}

/*tree view */
.tree-view {
  padding: 0 12px;
  line-height: 1.5;
}

.tree-node-inner {
  position: relative;
  height: 24px;
  cursor: pointer;
  line-height: 1.625;
}

.tree-node-inner-hover {
  background-color: rgb(255,239,178);
  border-radius: 0.25rem;
}

.tree-node-inner .tree-node-text {
  padding-left: 2.8rem;
  width: calc(100% - 1rem);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
}

.tree-node-inner .left-icon {
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  top: 0;
  left: 0;
  padding-left: 1.5rem;
}

.folder-toggle-icon {
  position: absolute;
  left: 0;
  color: #c0c0c0;
  line-height: 1.625;
  width: 1.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
}

.tree-node-icon {
  display: inline-block;
  width: 1rem;
  text-align: center;
  color: #b0b0b0;
}

.tree-node-inner .right-icon {
  position: absolute;
  top: 0;
  right: 1rem;
  bottom: 0;
  width: 1.5rem;
  color: #888;   
  z-index: 2;  
  font-size: 0.8125rem;
  text-align: center;
}

.tree-node-inner .right-icon i {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  font-size: 0.8125rem;
  line-height: 1.625rem !important;
}

.tree-node-hight-light {
  color: #fff;
  border-radius: 4px;
  background-color: #feac74 !important;
}

.tree-node-hight-light i {
  color:#fff;
}

.dropdown-menu {
  min-width: 8rem;
}

.parent-path {
  position: relative;
}

.parent-path .form-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  margin-left: 0.25rem;
  font-size: 0.9375rem;
}

