.wiki-side-panel .panel-top {
  background: #fff;
}
.wiki-side-nav {
  flex:auto;
  display:flex;
  flex-direction:column;
  overflow:hidden; /* for ff */
  border-right:1px solid #eee;
}

.wiki-pages-heading {
  position: relative;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.5rem 0 0.5rem 2rem;
  border-bottom: 1px solid #e8e8e8;
  line-height: 1.5;
  height: 40px;
  background-color: #f9f9f9;
  margin-bottom: 0;
}
.heading-icon {
  position: absolute;
  right: 1rem;
  top: 25%;
  color: #888;  
  font-size: 0.8125rem;
}
.wiki-pages-container {
  flex: 1;
  overflow: hidden;
  padding-bottom: 10px;
}
.wiki-pages-container:hover {
  overflow: auto;
}
.wiki-pages-container .tree-view {
  margin-left: -10px;
  padding-left:0;
}
.wiki-pages-container .article {
  padding: 0 20px;
}
.wiki-pages-container .tree-view {
  margin-top: 14px;
}

.wiki-md-viewer-rendered-content {
  padding: 30px 0 0;
}

img[src=""] {
  opacity: 0;
}

.wiki-side-panel {
  flex: 0 0 20%;
  display:flex;
  flex-direction:column;
  overflow:hidden;
}

@media (max-width: 767px) {
  .wiki-side-panel {
    z-index: 1051;
  }
}

.wiki-main-panel {
  flex: 1 0 80%;
  display:flex;
  flex-direction:column;
  min-height: 0;
}

.wiki-main-panel .main-panel-north {
  background-color: #fff;
}

.cur-view-content .wiki-page-container {
  margin: 0 -1rem -1.25rem;
  padding: 30px 1rem 1.25rem;
  display: flex;
  flex: 1;
  padding-left: 30px;
  overflow-y: auto;
}

.wiki-main-panel .cur-view-content .article {
  padding: 0 10px;
}

.wiki-main-panel .cur-view-content .article h1 {
  margin-top: 0;
}

.cur-view-content .wiki-page-container .outline-h2,
.cur-view-content .wiki-page-container .outline-h3 {
  height: 24px;
  font-size: 12px;
  color: #4d5156;
}

.cur-view-content .wiki-page-container .outline-h2.active,
.cur-view-content .wiki-page-container .outline-h3.active { 
  color: #eb8205;
}

.cur-view-content .wiki-page-container .seafile-markdown-outline {
  overflow-y: hidden;
  margin-right: 10px;
}

.cur-view-content .wiki-page-container .seafile-markdown-outline:hover {
  overflow-y: auto;
}

.cur-view-content .wiki-page-content {
  width: calc(100% - 200px);
  padding-right: 30px;
}

.cur-view-content .wiki-page-content .seafile-markdown-outline {
  position: fixed;
  padding-right: 1rem;
  top: 79px;
  right: 0;
  width: 200px;
  overflow: hidden;
}

.wiki-hide {
  display: none !important;
}

@media (max-width: 991.98px) {
  .cur-view-content .wiki-page-container {
    padding: 0 14px;
    padding-top: 30px;
  }
  .cur-view-content .wiki-page-content {
    width: 100%;
    padding-right: 0;
  }
  .cur-view-content .seafile-markdown-outline {
    display: none;
  }
}

.wiki-main .wiki-viewer-outline {
  position: relative;
  top: 0;
  padding: 0;
  list-style: none;
  border-left: solid 1px #eee;
}
.textindent-2 {
  text-indent: 18px;
}

.wiki-main .wiki-outline-item {
  padding: 3px 15px;
  font-size: 14px;
}

.wiki-outline-item a {
  display: block;
  color: #444;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wiki-outline-item a:hover {
  color: #eb8205;
  text-decoration: underline;
}

.wiki-outline-item-active { 
  border-left: 1px solid #eb8205;
}

.wiki-outline-item-active a {
  color: #eb8205 !important;
}

.wiki-page-ops {
    position:fixed;
    top:10px;
}

@media (min-width: 768px) {
    .wiki-page-ops:before {
        content:'';
        border-left:1px solid #ddd;
        position:absolute;
        top:3px;
        left:-16px;
        bottom:3px;
    }
}

.wiki-page-list-item {
    word-break:break-all;
    line-height:1.6;
    margin:3px 0;
}

.wiki-page-link,
.wiki-page-link:hover {
   font-size:1.15em;
   font-weight:normal;
   color:#444;
   margin-left:5px;
}

#wiki-page-last-modified {
    padding: 40px 10px;
    font-size:12px;
    color: #666;
}

.wiki-md-viewer-rendered-content.article h1 {
  margin-top: 0;
}

.wiki-page-content a {
    cursor: pointer;
}

.wiki-side-nav .wiki-page-content a {
  color: #212529;
  cursor: pointer;
}

.index-edit {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
}

.wiki-main-panel .wiki-page-content .ml-2 {
  text-decoration: underline;
}

.wiki-main-panel .wiki-page-content .ml-2:hover {
  text-decoration: underline;
  color:#eb8205;
}
