.wiki-nav-content {
  margin-top: 18px;
}
.wiki-nav-content a, .wiki-nav-content span {
  color: #4d5156;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.wiki-nav-content-highlight a {
  text-decoration: none;
  color: #eb8205;
}
.wiki-nav-content a:hover {
  text-decoration: none;
  color: #eb8205;
}
.switch-btn {
  position: absolute;
  left: 0;
  top: 2px;
  color: #c0c0c0;
  cursor: pointer;
  font-size: 12px;
  padding-right: 10px;
}